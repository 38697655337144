import React, { useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import InfoIcon from '../../assets/Icons/Info'
import EventPlayer from '../eventPlayer/eventPlayer'
import { PlaylistEntity } from '../../generated/graphql'
import PlayerControl from '../playerControl/playerControl'
import PlaylistInfo from '../playlistinfo/playlistinfo'
import Close from '../../assets/Icons/Close'

const Wrapper = styled.div`
  position: absolute;
  right: 0.9rem;
  bottom: 0.9rem;
  bottom: 2.9rem;
  width: calc(100% - 1.8rem);
  @media ${(props) => props.theme.breakpoints.md} {
    bottom: 0.9rem;
    width: auto;
  }
`

const Card = styled.div`
  border-radius: 0.9rem;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.md} {
    max-width: auto;
    width: auto;
    padding: 1.8rem;
  }
  z-index: 10;
  background: linear-gradient(0deg, #ffffff, #ffffff), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 1.8rem 0.9rem;
  display: flex;
  justify-content: center;
`

const InfoOverlay = styled.div<{ infoOpen: boolean }>`
  position: absolute;
  right: 0rem;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.lightMint};
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0.9rem;
  padding: 4rem;
  width: 100%;
  right: ${(props) => (props.infoOpen ? 0 : '-100%')};
  transition: right 0.3s ease-in;
  overflow-y: auto;
  max-height: 100vh;
  * {
    font-size: 1.8rem;
    font-family: ${(p) => p.theme.fonts.primary};
  }
  @media ${(props) => props.theme.breakpoints.md} {
    width: 80%;
    padding: 12rem;
    * {
      font-size: 2.4rem;
    }
  }
`

const InfoHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
  span {
    margin-top: 0.8rem;
    margin-left: 1.8rem;
    font-size: 1.5rem;
  }
`

const InfoClose = styled.div`
  position: absolute;
  right: 1.8rem;
  top: 1.8rem;
  cursor: pointer;
`

const DestinationDetailBottomBar = ({
  playlist,
  playlists,
  destinationId,
  setPlaylist,
  destinationTitle,
}: {
  playlist: PlaylistEntity
  playlists: PlaylistEntity[] | undefined
  setPlaylist: (playlist: PlaylistEntity) => void
  destinationId: number
  destinationTitle: string
}) => {
  if (!playlists) return null
  const [isPlaylistInfoOpen, setIsPlaylistInfoOpen] = useState(false)
  return (
    <>
      <InfoOverlay infoOpen={isPlaylistInfoOpen}>
        <InfoHeader>
          <InfoIcon />
          <span>{playlist.attributes?.title}</span>
        </InfoHeader>
        <ReactMarkdown>{playlist.attributes?.playlistInfo as string}</ReactMarkdown>
        <InfoClose onClick={() => setIsPlaylistInfoOpen(false)}>
          <Close />
        </InfoClose>
      </InfoOverlay>
      <Wrapper>
        <EventPlayer />
        {!!playlist.attributes?.playlistInfo && (
          <PlaylistInfo setIsPlaylistInfoOpen={setIsPlaylistInfoOpen} />
        )}
        <Card>
          <PlayerControl
            destinationTitle={destinationTitle}
            playlists={playlists}
            playlist={playlist}
            destinationId={destinationId}
          />
        </Card>
      </Wrapper>
    </>
  )
}

export default DestinationDetailBottomBar

import { gql } from '@apollo/client'

const DESTINATION_QUERY = gql`
  query Destination($id: ID) {
    destination(id: $id) {
      data {
        id
        attributes {
          title
          defaultPlaylist {
            data {
              id
            }
          }
          playlists(sort: "intensity:desc") {
            data {
              id
              attributes {
                title
                intensity
                playlistInfo
                cover {
                  data {
                    id
                    attributes {
                      url
                      alternativeText
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default DESTINATION_QUERY

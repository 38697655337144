import React from 'react'

const LiveEventIcon = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.5" cy="16.5" r="12" stroke="black" />
    <path
      d="M20 22C21.8135 20.7891 23 18.7772 23 16.5C23 14.2228 21.8135 12.2109 20 11"
      stroke="black"
      strokeLinecap="round"
    />
    <path
      d="M13 22C11.1865 20.7891 10 18.7772 10 16.5C10 14.2228 11.1865 12.2109 13 11"
      stroke="black"
      strokeLinecap="round"
    />
    <circle cx="16.5" cy="16.5" r="1" fill="black" stroke="black" />
  </svg>
)

export default LiveEventIcon

import { gql } from '@apollo/client'

const SEARCH_QUERY = gql`
  query PlaylistDestinations {
    destinations {
      data {
        id
        attributes {
          title
          playlists(pagination: { limit: 1000 }) {
            data {
              id
              attributes {
                title
                cover {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SEARCH_QUERY

export const DESTINATION_QUERY = gql`
  query DESTINATION_QUERY($id: ID!) {
    destinations(filters: { playlists: { id: { eq: $id } } }) {
      data {
        id
      }
    }
  }
`

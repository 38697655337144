/* eslint-disable react/no-unknown-property */
import React from 'react'

const Close = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5048 7.50161L16.5032 16.4873L25.5016 7.50161L16.5159 16.5L25.5016 25.4984L16.5032 16.5127L7.5048 25.4984L16.4905 16.5L7.5048 7.50161Z"
      stroke="black"
      strokeLinejoin="round"
    />
  </svg>
)

export default Close

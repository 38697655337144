import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Image from '../image/image'
import { Span } from '../../styles/typography'

interface Props {
  index: number
  data: {
    id: string
    attributes: {
      title: string
      coverImage: {
        data: {
          attributes: any
        }
      }
    }
  }
}

const Card = styled(Link)<{ index: number }>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  img {
    border-radius: 10px;
    animation: SCALE_IN 100ms forwards;
    animation-delay: ${(props) => props.index * 40 + 200}ms;
    transform: scale(0);
  }
`

const DestinationTitle = styled(Span)<{ index: number }>`
  color: black;
  display: block;
  font-size: 1.5rem;
  // padding-top: 10px;
  animation: FADE_IN 100ms forwards;
  animation-delay: ${(props) => props.index * 150 + 250}ms;
  opacity: 0;
  position: absolute;
  bottom: 2.4rem;
  width: 100%;
  text-align: center;
  font-family: ${(p) => p.theme.fonts.primary};
  font-size: 1.5rem;
  letter-spacing: 15%;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
`
const DestinationCard = ({ data, index }: Props) => {
  const { url, alternativeText } = data.attributes.coverImage.data.attributes
  return (
    <Card index={index} to={`/destination/${data.id}`}>
      <Image src={url} alt={alternativeText} aspectRatio={{ width: 270, height: 270 }} />
      <DestinationTitle index={index}>{data.attributes.title}</DestinationTitle>
    </Card>
  )
}

export default DestinationCard

import React, { useEffect, useRef, useState } from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { DraggableCore } from 'react-draggable'
import EventEmitter from 'events'
import DESTINATIONS_QUERY from './destinations.query'
import DestinationSlider from './destinationSlider'
import Header from '../header/header'

/* width: ${(props) => props.panelWidth}px;
min-width: ${(props) => props.panelWidth}px; */
const Wrapper = styled(motion.div)`
  height: 100%;
  background: white;
  z-index: 90;
  position: relative;
  position: absolute;
  right: 0;
  @media ${(props) => props.theme.breakpoints.md} {
    position: relative;
  }
  & > div {
    overflow: hidden;
    overflow-y: scroll;
    height: 100%;
    width: auto;
    max-width: 100vw;
    padding: 0.9rem;
    @media ${(props) => props.theme.breakpoints.md} {
      overflow-y: hidden;
      padding: 0;
    }
  }
`

/* right: ${(props) => props.panelWidth}px; */
const Handle = styled(motion.button)`
  position: fixed;
  width: 2.5rem;
  height: 8.4rem;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  z-index: 99;
  border: none;
  border-radius: 9px 0px 0px 9px;
  /* display: none;
  @media ${(props) => props.theme.breakpoints.md} {
    display: block;
  } */
  span {
    height: 6rem;
    width: 1px;
    display: block;
    margin-left: 4px;
    background: black;
  }
`

const Destinations = React.memo(({ user }: { user: CognitoUser | undefined }) => {
  const { data } = useQuery(DESTINATIONS_QUERY)
  const browserLocation = useLocation()

  const [maxPanelWidth, setMaxPanelWidth] = useState<number>(0)
  const [panelWidth, setPanelWidth] = useState<number>(0)
  const sidebarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 1024) {
        setMaxPanelWidth(window.innerWidth)
        setPanelWidth(window.innerWidth)
        return
      }
      const rect = sidebarRef.current?.getBoundingClientRect()
      if (!rect) return
      const { height } = rect
      const pw = ((height - 70 - 18) / 3) * 2 + 9 + 9
      setMaxPanelWidth(Math.min(pw, window.innerWidth))
      if (!panelWidth) {
        setPanelWidth(Math.min(pw, window.innerWidth))
      }
    }
    onResize()
    window.addEventListener('resize', onResize)
    window.addEventListener('orientationchange', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('orientationchange', onResize)
    }
  }, [data])

  useEffect(() => {
    if (window.innerWidth >= 1024) return
    setPanelWidth(0)
  }, [browserLocation])

  useEffect(() => {
    // close the playlist selector when the playlist changes
    ;((window as any).eventHandler as EventEmitter).on('CLOSE_ALL', () => {
      setPanelWidth(0)
    })
  }, [])

  const updateDrag = (
    e: any,
    location: {
      deltaX: number
      deltaY: number
      lastX: number
      lastY: number
      x: number
      y: number
    }
  ) => {
    setPanelWidth(Math.max(0, Math.min(panelWidth - location.deltaX, maxPanelWidth)))
  }

  // const onHandleClick = () => {
  //   if (panelWidth < maxPanelWidth / 2) {
  //     setPanelWidth(maxPanelWidth)
  //   } else {
  //     setPanelWidth(0)
  //   }
  // }

  const onEnd = (
    e: any,
    location: {
      deltaX: number
      deltaY: number
      lastX: number
      lastY: number
      x: number
      y: number
    }
  ) => {
    if (panelWidth === 0) {
      setPanelWidth(maxPanelWidth)
    } else if (panelWidth === maxPanelWidth) {
      setPanelWidth(0)
    } else if (panelWidth > maxPanelWidth / 2) {
      setPanelWidth(maxPanelWidth)
    } else {
      setPanelWidth(0)
    }
  }

  // const animatedMaxPanelWidth = useMotionValue(maxPanelWidth)
  // const animatedPanelWidth = useMotionValue(panelWidth)

  if (!data) return null
  return (
    <Wrapper animate={{ width: panelWidth, maxWidth: panelWidth }}>
      <DraggableCore onStop={onEnd} onDrag={updateDrag}>
        <Handle animate={{ right: panelWidth }}>
          <span />
        </Handle>
      </DraggableCore>
      <div ref={sidebarRef} style={{ minWidth: maxPanelWidth }}>
        <Header
          user={user}
          onClose={
            panelWidth !== 0 && window.innerWidth < 1024 ? () => setPanelWidth(0) : undefined
          }
        />
        {/* <Title>Choose your destination</Title> */}
        <DestinationSlider data={data.destinations.data} />
      </div>
    </Wrapper>
  )
})

export default Destinations

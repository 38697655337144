import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import Fuse from 'fuse.js'
import styled from 'styled-components'
import mixpanel from 'mixpanel-browser'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import SEARCH_QUERY, { DESTINATION_QUERY } from './search.query'
import { DestinationEntity, DestinationEntityResponseCollection } from '../../generated/graphql'
import client from '../../utils/apollo'
import PlaylistCard from '../playlistCard/playlistCard'

const Wrapper = styled('div')<{ show: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  transition: transform 0.2s ease-in-out;
  background: white;

  z-index: 20;
  padding: 10rem 2rem 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Input = styled.input`
  width: 100%;
  font-size: 2.4rem;
  font-weight: 300;
  border: none;
  outline: none;
  shadow: none;
  background: transparent;
  letter-spacing: 6%;
  margin-bottom: 2.4rem;
  ::placeholder {
    opacity: 0.2;
  }
`

const ResultGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 50%);
  column-gap: 1.2rem;
  margin-bottom: 2.4rem;
`

const DestinationHeadline = styled.span`
  font-size: 2.4rem;
  font-family: ${(props) => props.theme.fonts.primary};
  display: block;
  margin-bottom: 1.5rem;
`

const DestinationItem = ({
  query,
  destination,
  onPlaylistClick,
}: {
  query: string | undefined
  destination: DestinationEntity
  onPlaylistClick: (id: string) => Promise<void>
}) => {
  const fuse = useMemo(
    () =>
      new Fuse(destination.attributes?.playlists?.data || [], {
        keys: ['attributes.title', 'attributes.playlists.data.attributes.title'],
      }),
    [destination]
  )
  const items = query
    ? fuse.search(query)
    : destination.attributes?.playlists?.data.map((item) => ({ item })) || []
  return (
    <ResultGrid>
      {items.map((f) => (
        <PlaylistCard key={f.item.id} onClick={onPlaylistClick} p={f.item} />
      ))}
    </ResultGrid>
  )
}

const Search = ({ show, onSelect }: { show: boolean; onSelect: () => void }) => {
  const [query, setQuery] = useState('')
  const navigate = useNavigate()
  const { loading, data } = useQuery<{ destinations: DestinationEntityResponseCollection }>(
    SEARCH_QUERY
  )

  const onPlaylistClick = async (id: string) => {
    const res = await client.query<{ destinations: DestinationEntityResponseCollection }>({
      query: DESTINATION_QUERY,
      variables: { id },
    })
    if (!res || !res.data.destinations || !res.data.destinations.data[0]) return
    onSelect()
    navigate(`/destination/${res.data.destinations.data[0].id}/${id}`)
  }

  const debouncedListener = useCallback(
    debounce((q) => {
      if (!q) return
      mixpanel.track('user-searched', {
        query: q,
      })
    }, 500),
    []
  )

  useEffect(() => {
    debouncedListener(query)
  }, [query])

  return (
    <Wrapper show={show}>
      <Input placeholder="Find your playlist" onChange={(e) => setQuery(e.target.value)} />
      {!loading && data && show && (
        <div>
          {data.destinations.data.map((d) => (
            <div key={d.id}>
              <DestinationHeadline>{d.attributes?.title}</DestinationHeadline>
              <DestinationItem query={query} destination={d} onPlaylistClick={onPlaylistClick} />
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

export default Search

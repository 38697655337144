import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import { EventContext } from '../../pages/home/Home'
import LiveEventIcon from '../../assets/Icons/live'
import { PlayerContext } from '../../utils/player'
import Close from '../../assets/Icons/Close'

const Wrapper = styled.div<{ isPlaying: boolean }>`
  width: 6rem;
  height: 6rem;
  background: linear-gradient(0deg, #ffffff, #ffffff), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.9rem;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  svg {
    animation: rotator 1.5s infinite;
    animation-timing-function: linear;
    circle {
      box-sizing: border-box;
    }
  }
  ${(props) =>
    props.isPlaying
      ? `
    width: 37rem;
    height: auto;
    aspect-ratio: 16 / 9;
  `
      : ''}
  @keyframes rotator {
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const CloseIcon = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  top: 0.9rem;
  right: 0.9rem;
  background-color: white;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
    animation: none;
  }
`

const EventPlayer = () => {
  const events = useContext(EventContext)
  const player = useContext(PlayerContext)
  const [isPlaying, setIsPlaying] = useState(false)
  const event = events?.data ? events?.data[0] : undefined
  // hide the live event indicator if there is no event
  if (!event) return null

  // pause player if live event is playing
  if (isPlaying && player.isPlaying()) {
    player.pause()
  }

  return (
    <Wrapper isPlaying={isPlaying} onClick={() => setIsPlaying(!isPlaying)}>
      {isPlaying && (
        <CloseIcon onClick={() => setIsPlaying(false)}>
          <Close />
        </CloseIcon>
      )}
      {!isPlaying && <LiveEventIcon />}
      {isPlaying && <ReactPlayer playing url={event.attributes?.twitchURL} />}
    </Wrapper>
  )
}

export default EventPlayer

/* eslint-disable react/no-unknown-property */
import React from 'react'

const Settings = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 33">
    <path
      stroke="#000"
      d="M18.8 8c-.5-2.5-4-2.5-4.6 0-.2.9-1.3 1.3-2 .8C10 7.5 7.4 10 8.7 12.1c.5.8.1 1.9-.8 2-2.5.6-2.5 4.2 0 4.7.9.2 1.3 1.3.8 2-1.3 2.2 1.2 4.7 3.3 3.4.8-.5 1.9-.1 2 .8.6 2.5 4.2 2.5 4.7 0 .2-.9 1.3-1.3 2-.8 2.2 1.3 4.7-1.2 3.4-3.3-.5-.8-.1-1.9.8-2 2.5-.6 2.5-4.2 0-4.7-.9-.2-1.3-1.3-.8-2 1.3-2.2-1.2-4.7-3.3-3.4-.8.5-1.9.1-2-.8Z"
    />
  </svg>
)

export default Settings

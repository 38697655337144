import React from 'react'
import styled from 'styled-components'
import ImgixClient from '@imgix/js-core'
import { AspectRatio } from '../../mixins/mixins'

const client = new ImgixClient({
  domain: 'antless-media.imgix.net',
})

interface AspectRatio {
  width: number
  height: number
}

const ImageWrapper = styled.div<{ aspectRatio?: AspectRatio }>`
  position: relative;
  width: 100%;
  ${(props) => (props.aspectRatio ? AspectRatio(props.aspectRatio) : '')};
`

const Image = (props: { src: string; alt: string; aspectRatio?: AspectRatio }) => {
  const { src, alt, aspectRatio } = props
  const relativeSrc = src.replace(
    'https://antless-strapi-media-prod.s3.eu-central-1.amazonaws.com/',
    ''
  )
  const defaultSrc = client.buildURL(relativeSrc, { w: 1024 })
  // const filename = src.split('/').pop() as string
  // const url = getCloudfrontURL(src.replace(filename, ''))
  const srcset = client.buildSrcSet(relativeSrc, { auto: 'format' })
  // const defaultSrc = `${url}${mapping[1][0]}_${filename} ${mapping[1][1]}w`
  return (
    <ImageWrapper aspectRatio={aspectRatio}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <img {...props} loading="eager" src={defaultSrc} srcSet={srcset} alt={alt} />
    </ImageWrapper>
  )
}

Image.defaultProps = {
  aspectRatio: null,
}

export default Image

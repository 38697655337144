import { gql } from '@apollo/client'

export const PLAYLISTS_QUERY = gql`
  query FavoritesPlaylist($ids: [ID]) {
    playlists(filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          title
          cover {
            data {
              id
              attributes {
                url
                alternativeText
                name
              }
            }
          }
        }
      }
    }
  }
`
export const PLAYLIST_QUERY = gql`
  query FavoritePlaylist($id: ID!) {
    playlist(id: $id) {
      data {
        id
        attributes {
          title
          cover {
            data {
              id
              attributes {
                url
                alternativeText
                name
              }
            }
          }
          tracks {
            data {
              id
              attributes {
                title
                source {
                  data {
                    id
                    attributes {
                      url
                      mime
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

import { gql } from '@apollo/client'

const EVENTS_QUERY = gql`
  query Events($time: DateTime) {
    events(
      filters: { and: [{ eventStart: { lte: $time } }, { eventEnd: { gte: $time } }] }
      sort: "eventStart"
    ) {
      data {
        id
        attributes {
          title
          eventEnd
          twitchURL
          eventStart
          public
          companies {
            data {
              id
            }
          }
        }
      }
    }
  }
`

export default EVENTS_QUERY

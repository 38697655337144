/* eslint-disable react/no-unknown-property */
import React from 'react'

const Bookmark = ({ active }: { active: boolean }) => (
  <svg
    width="19"
    height="22"
    viewBox="-3 -3 25 28"
    fill={active ? 'black' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L18 1V21L9.5 16.0263L1 21L1 1Z" stroke="black" strokeLinejoin="round" />
  </svg>
)

export default Bookmark

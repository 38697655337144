/* eslint-disable react/no-unknown-property */
import React from 'react'

const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30">
    <circle cx="12.3" cy="13" r="8.5" stroke="#000" transform="rotate(-45 12.3 13)" />
    <path stroke="#000" d="m18.3 19.1 4.8 4.8" />
  </svg>
)

export default Search

import styled from 'styled-components'

export const Span = styled.span`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.07em;
`
export const H1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 2.4rem;
  font-weight: normal;
  letter-spacing: 0.06em;
  margin: 0;
`

export const H3 = styled.h3`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.07em;
  margin: 0;
`

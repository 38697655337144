import { API, Auth } from 'aws-amplify'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { attachCompanyLicenseCodeToUser } from '../../graphql/mutations'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: 300;
  letter-spacing: 0.05em;
  p {
    letter-spacing: 0.18em;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  input {
    padding: 1.4rem 1.2rem;
    background-color: white;
    border: none;
    border-radius: 0.9rem;
    min-width: 20rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
    margin-bottom: 0.9rem;
    @media ${(props) => props.theme.breakpoints.md} {
      margin-right: 0.9rem;
      margin-bottom: 0;
    }
  }
  button {
    border: 1px solid black;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: transparent;
    border-radius: 0.9rem;
    padding: 1.3rem 2.4rem;
    cursor: pointer;
  }
  & > div {
    display: flex;
    flex-direction: column;
    @media ${(props) => props.theme.breakpoints.md} {
      flex-direction: row;
    }
    & > * {
      height: 4.1rem;
    }
  }
`

const StatusLabel = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  margin-top: 1rem;
  font-size: 1.3rem;
  display: block;
`

const CompanyCode: React.FC = () => {
  const navigate = useNavigate()
  const [code, setCode] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const verifyCode = async () => {
    setError(undefined)
    setLoading(true)
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      await API.graphql({
        query: attachCompanyLicenseCodeToUser,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        authToken: token,
        variables: {
          licenseCode: code,
        },
      })
      setLoading(false)
      setSuccess(true)
      setTimeout(() => {
        navigate('/tutorial')
        window.location.reload()
      }, 2000)
    } catch (e: any) {
      setLoading(false)
      if (e.errors) {
        setError(e.errors[0].message)
      }
    }
  }

  return (
    <Wrapper>
      <p>Du hast einen Lizenz-Code von einem Unternehmen (beginnt mit ANTxxx)?</p>
      <div>
        <input onChange={(e) => setCode(e.target.value)} placeholder="Code eingeben" type="text" />
        <button onClick={verifyCode} type="button">
          Aktivieren
        </button>
      </div>
      {error && <StatusLabel color="#ef233c">{error}</StatusLabel>}
      {success && <StatusLabel color="#2a9d8f">Successfully linked license code</StatusLabel>}
      {loading && <StatusLabel color="black">Loading ...</StatusLabel>}
    </Wrapper>
  )
}

export default CompanyCode

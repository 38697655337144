import React from 'react'
import styled from 'styled-components'

const Box = styled.button`
  padding: 2rem 4rem;
  padding-bottom: 1.6rem;
  border: 1px solid black;
  border-radius: 0.6rem;
  background: white;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Button: React.FC<{ onClick: () => void; children: any }> = ({ children, onClick }) => (
  <Box onClick={onClick}>{children}</Box>
)

export default Button

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attachCompanyLicenseCodeToUser = /* GraphQL */ `
  mutation AttachCompanyLicenseCodeToUser($licenseCode: String!) {
    attachCompanyLicenseCodeToUser(licenseCode: $licenseCode)
  }
`;
export const createStripeCustomerPortalSession = /* GraphQL */ `
  mutation CreateStripeCustomerPortalSession {
    createStripeCustomerPortalSession
  }
`;
export const createStripeCheckoutSession = /* GraphQL */ `
  mutation CreateStripeCheckoutSession($duration: SubscriptionDuration!) {
    createStripeCheckoutSession(duration: $duration)
  }
`;
export const createFavorites = /* GraphQL */ `
  mutation CreateFavorites(
    $input: CreateFavoritesInput!
    $condition: ModelFavoritesConditionInput
  ) {
    createFavorites(input: $input, condition: $condition) {
      id
      playlistId
      destinationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateFavorites = /* GraphQL */ `
  mutation UpdateFavorites(
    $input: UpdateFavoritesInput!
    $condition: ModelFavoritesConditionInput
  ) {
    updateFavorites(input: $input, condition: $condition) {
      id
      playlistId
      destinationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteFavorites = /* GraphQL */ `
  mutation DeleteFavorites(
    $input: DeleteFavoritesInput!
    $condition: ModelFavoritesConditionInput
  ) {
    deleteFavorites(input: $input, condition: $condition) {
      id
      playlistId
      destinationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

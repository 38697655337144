import React from 'react'
import { CognitoUser } from '@aws-amplify/auth'
import { TrackStatus } from './player'

const GlobalContextContext = React.createContext<{
  user: CognitoUser | undefined
  signOut: any
  playerStatus: TrackStatus | undefined
}>(undefined as any)

export default GlobalContextContext

import { gql } from '@apollo/client'

const RECOMMENDED_PLAYLIST = gql`
  query RECOMMENDED_PLAYLIST($currentTime: Time) {
    destinations {
      data {
        id
        attributes {
          playlists(
            filters: {
              showAtRecommendedTime: { eq: true }
              recommendedTime: { lte: $currentTime }
              recommendedTimeEnd: { gte: $currentTime }
            }
          ) {
            data {
              id
              attributes {
                title
                recommendedTime
                recommendedTimeEnd
                cover {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default RECOMMENDED_PLAYLIST

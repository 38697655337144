import React, { useState } from 'react'
import styled from 'styled-components'
import InfoIcon from '../../assets/Icons/Info'

const Wrapper = styled.div`
  width: 6rem;
  height: 6rem;
  background: linear-gradient(0deg, #ffffff, #ffffff), rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(30px);
  border-radius: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.9rem;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
`

const PlaylistInfo = ({
  setIsPlaylistInfoOpen,
}: {
  setIsPlaylistInfoOpen: (open: boolean) => void
}) => {
  return (
    <Wrapper onClick={() => setIsPlaylistInfoOpen(true)}>
      <InfoIcon />
    </Wrapper>
  )
}

export default PlaylistInfo

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Amplify } from 'aws-amplify'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import { Authenticator } from '@aws-amplify/ui-react'
import App from './App'
import 'normalize.css'
import reportWebVitals from './reportWebVitals'
import client from './utils/apollo'
import awsExports from './aws-exports'
import theme from './styles/theme'

Amplify.configure(awsExports)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </BrowserRouter>
    </ThemeProvider>
  </ApolloProvider>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

export default {
  fonts: {
    primary: '"Nexa", sans-serif',
  },
  colors: {
    light: '#FCFBF5',
    lightMint: '#D9E4E8',
    mint: '#e0f2f1',
    antless1: '#B6CAD1',
  },
  breakpoints: {
    md: '(min-width: 1024px)',
  },
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/generic/Button'
import Slide1 from '../../assets/tutorial/slide1.jpg'
import Slide2 from '../../assets/tutorial/slide2.png'
import Slide3 from '../../assets/tutorial/slide3.png'
import Slide4 from '../../assets/tutorial/slide4.png'
import AnimatedLogo from '../../assets/animated_logo.gif'
import Bookmark from '../../assets/Icons/Bookmark'
import Shuffle from '../../assets/Icons/Shuffle'
import IntensitySwitch from '../../assets/Icons/IntensitySwitch'

const DestinationPlaceholder = styled.div`
  background: ${(props) => props.theme.colors.antless1};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.md} {
    border-radius: 1.5rem;
  }
  img {
    width: 75px;
    height: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0.9rem;
  @media ${(props) => props.theme.breakpoints.md} {
    padding: 0;
    flex-direction: row;
  }
  & > div {
    height: 50%;
    width: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 1.8rem;
    justify-content: center;
    align-items: center;
    @media ${(props) => props.theme.breakpoints.md} {
      height: 100%;
      width: 50%;
    }
  }
`

const FullScreenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
`

const ContentContainer = styled.div`
  padding: 5rem;
  text-align: center;
  display: block;
  overflow: auto;
  max-height: 100%;
  font-family: ${(props) => props.theme.fonts.primary};
  h1 {
    font-weight: 300;
    margin-bottom: 2.1rem;
    font-size: 2rem;
    line-height: 2.3rem;
    @media ${(props) => props.theme.breakpoints.md} {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
  }
  p {
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 3rem;
    @media ${(props) => props.theme.breakpoints.md} {
      font-size: 2.4rem;
      line-height: 3.3rem;
    }
    svg {
      height: 1.2em;
      margin-bottom: -0.25em;
    }
  }
  button {
    font-size: 1.6rem;
    @media ${(props) => props.theme.breakpoints.md} {
      font-size: 1.8rem;
    }
  }
`

const slides = [
  {
    // image: (
    //   <DestinationPlaceholder>
    //     <div>
    //       <img alt="Antless Logo" src={AnimatedLogo} />
    //     </div>
    //   </DestinationPlaceholder>
    // ),
    image: <FullScreenImage src={Slide1} alt="intro slide 2" />,
    header: 'Hi and welcome to ANTLESS',
    copy: (
      <>
        We’re happy that you join us on our journey to create better working experiences.
        <br />
        Before you get started, let’s give you a quick tour to show you how it works.
      </>
    ),
  },
  {
    image: <FullScreenImage src={Slide2} alt="intro slide 2" />,
    header: 'Destinations',
    copy: (
      <>
        At Antless you can follow your intuition. You know what is best for you.
        <br />
        All you have to do: choose one of our Destinations and decide which goal you want to pursue
        when working on your tasks.
      </>
    ),
  },
  {
    image: <FullScreenImage src={Slide3} alt="intro slide 2" />,
    header: 'Channels',
    copy: (
      <>
        In every Destination you can select different Channels from high (at the top) to low (at the
        bottom) intensity.
        <br />
        All of them are carefully curated for you on the basis of music psychology – for more focus,
        creativity or relaxation at work.
        <br />
        Certain channels can also appear in multiple destinations if they meet the required
        criteria.
        <br />
        <br />
        Our tip: Always let the music play for a few minutes so it can develop its full effect.
      </>
    ),
  },
  {
    image: <FullScreenImage src={Slide4} alt="intro slide 2" />,
    header: 'Music Player',
    copy: (
      <>
        Easy to use - no distractions. Our music player is completely re-designed and reduced to a
        minimum. This way you can fully concentrate on your work and get in the flow.
        <br />
        If you don&apos;t like a track, you can always use the shuffle function. <Shuffle />
        <br />
        Use the <Bookmark active={false} /> to tag your favorite channels and reach your goals even
        faster.
      </>
    ),
  },
  {
    image: (
      <DestinationPlaceholder>
        <div>
          <img alt="Antless Logo" src={AnimatedLogo} />
        </div>
      </DestinationPlaceholder>
    ),
    header: 'Ready to feel the power of music and get in the flow?',
    copy: null,
  },
]

const Tutorial = () => {
  const [active, setActive] = useState(0)
  const navigate = useNavigate()
  const activeSlide = slides[active]
  const next = () => {
    if (active >= slides.length - 1) {
      return navigate('/')
    }
    return setActive(active + 1)
  }
  return (
    <Wrapper>
      <div>{activeSlide.image}</div>
      <div>
        <ContentContainer>
          <h1>{activeSlide.header}</h1>
          <p>{activeSlide.copy}</p>
          <Button onClick={next}>Continue</Button>
        </ContentContainer>
      </div>
    </Wrapper>
  )
}

export default Tutorial

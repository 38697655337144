/* eslint-disable react/no-unknown-property */
import React from 'react'

const VolumeDown = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.81819 5.13951V5.6395C5.93836 5.63951 6.05451 5.59623 6.14538 5.51759L5.81819 5.13951ZM10.1818 1.36328H10.6818C10.6818 1.16759 10.5677 0.989883 10.3897 0.908532C10.2117 0.827181 10.0026 0.85714 9.85464 0.985197L10.1818 1.36328ZM2.42426 5.1395L2.42426 4.6395C2.29165 4.6395 2.16447 4.69218 2.0707 4.78595C1.97693 4.87972 1.92426 5.0069 1.92426 5.1395H2.42426ZM2.42426 9.85979H1.92426C1.92426 10.1359 2.14811 10.3598 2.42426 10.3598V9.85979ZM5.81819 9.85979L6.14538 9.4817C6.05451 9.40306 5.93836 9.35979 5.81819 9.35979V9.85979ZM10.1818 13.636L9.85464 14.0141C10.0026 14.1422 10.2117 14.1721 10.3897 14.0908C10.5677 14.0094 10.6818 13.8317 10.6818 13.636H10.1818ZM6.14538 5.51759L10.509 1.74137L9.85464 0.985197L5.49101 4.76142L6.14538 5.51759ZM2.42426 5.6395H5.81819V4.63951L2.42426 4.6395L2.42426 5.6395ZM2.92426 9.85979V5.1395H1.92426V9.85979H2.92426ZM5.81819 9.35979H2.42426V10.3598H5.81819V9.35979ZM10.509 13.2579L6.14538 9.4817L5.49101 10.2379L9.85464 14.0141L10.509 13.2579ZM9.68183 1.36328V13.636H10.6818V1.36328H9.68183Z"
      fill="black"
    />
    <path
      d="M13.0909 5.45508C13.0909 5.45508 13.5758 5.96644 13.5758 7.50053C13.5758 9.03462 13.0909 9.54599 13.0909 9.54599"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default VolumeDown

import { css } from 'styled-components'

export const AspectRatio = ({ width, height }: { width: number; height: number }) => css`
  position: relative;
  width: 100%;
  padding-top: calc(${height} / ${width} * 100%);
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

export default AspectRatio

/* eslint-disable react/no-unknown-property */
import React from 'react'

const IntensitySwitch = ({ active = false }: { active: boolean }) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_770_4438)">
      <path
        d="M23.3649 18C24.1401 18 24.6205 18.8439 24.2247 19.5105L17.3599 31.0725C16.9724 31.7251 16.0276 31.7251 15.6401 31.0725L8.77525 19.5105C8.37946 18.8439 8.85987 18 9.63511 18H23.3649Z"
        stroke="black"
        fill={active ? 'black' : undefined}
      />
      <path
        d="M9.63511 15C8.85987 15 8.37946 14.1561 8.77525 13.4895L15.6401 1.92753C16.0276 1.27492 16.9724 1.27492 17.3599 1.92753L24.2247 13.4895C24.6205 14.1561 24.1401 15 23.3649 15L9.63511 15Z"
        stroke="black"
        fill={active ? 'black' : undefined}
      />
    </g>
    <defs>
      <clipPath id="clip0_770_4438">
        <rect width="33" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default IntensitySwitch

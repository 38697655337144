/* eslint-disable react/no-unknown-property */
import React from 'react'

const Pause = () => (
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="6" height="21.9999" rx="0.5" stroke="black" />
    <rect x="10.5" y="0.5" width="6" height="21.9999" rx="0.5" stroke="black" />
  </svg>
)

export default Pause

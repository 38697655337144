import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { API, Auth } from 'aws-amplify'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import EventEmitter from 'events'
import Bookmark from '../../assets/Icons/Bookmark'
import Pause from '../../assets/Icons/Pause'
import Play from '../../assets/Icons/Play'
import Shuffle from '../../assets/Icons/Shuffle'
import { PlaylistEntity } from '../../generated/graphql'
import { Span } from '../../styles/typography'
import { PlayerContext, TrackStatus } from '../../utils/player'
import { createFavorites, deleteFavorites } from '../../graphql/mutations'
import Spinner from '../generic/Spinner'
import { listFavorites } from '../../graphql/queries'
import { ListFavoritesQuery } from '../../API'
import GlobalContextContext from '../../utils/globalContext'
import VolumeDown from '../../assets/Icons/VolumeDown'
import VolumeUp from '../../assets/Icons/VolumeUp'
import IntensitySwitch from '../../assets/Icons/IntensitySwitch'
import PlaylistCard from '../playlistCard/playlistCard'

const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.md} {
    max-width: 27rem;
  }
  text-align: center;
  // padding-bottom: 3rem;
  .rc-slider {
    width: 100%;
    height: 30px;
    .rc-slider-step {
      height: 1px;
    }
    .rc-slider-track {
      height: 1px;
      background-color: black;
    }
    .rc-slider-rail {
      height: 1px;
      opacity: 0.5;
      background-color: black;
    }
    .rc-slider-handle {
      margin-top: -7px;
      border: 1px solid black !important;
      border-color: black !important;
      background: white;
      opacity: 1;
      box-shadow: none !important;
    }
  }
`

const Icon = styled.button`
  border: none;
  height: 5rem;
  width: 5rem;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 100ms ease;
  svg {
    width: 80%;
    aspect-ratio: 1;
    height: auto;
  }
  &:hover {
    transform: scale(1.05);
  }
`

const IconBar = styled.div`
  display: flex;
  align-items: center;
  gap: 1.4rem;
  @media screen and (${(props) => props.theme.breakpoints.md}) {
    gap: 2.4rem;
  }
  button {
    background: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  // & > button:nth-child(2) {
  //   height: 8rem;
  //   width: 8rem;
  // }
`

const TrackAndArtist = styled(Span)`
  white-space: nowrap;
  text-align: left;
  width: 100%;
  bottom: 0;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    &:last-of-type {
      color: #424242;
      font-size: 1.4rem;
    }
  }
`

const PlaylistSelector = styled.div<{ modalOpen: boolean }>`
  max-height: ${(props) => (props.modalOpen ? '70vh' : '0')};
  transition: max-height 300ms ease;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  & > span {
    font-family: ${(props) => props.theme.fonts.primary};
    text-align: left;
    font-size: 2.4rem;
    display: block;
    width: 100%;
    margin-bottom: 2.2rem;
  }
  & > div {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const PlayIcon = ({
  isLoading,
  isPlaying,
  onClick,
}: {
  isLoading: boolean
  isPlaying: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) => {
  if (isLoading)
    return (
      <Icon>
        <Spinner />
      </Icon>
    )
  return <Icon onClick={onClick}>{isPlaying ? <Pause /> : <Play />}</Icon>
}

const PlayerControl = ({
  playlist,
  playlists,
  destinationId,
  destinationTitle,
}: {
  playlist: PlaylistEntity
  playlists: PlaylistEntity[]
  destinationId: number
  destinationTitle: string
}) => {
  const player = useContext(PlayerContext)
  const [isPlaylistSelectorOpen, setIsPlaylistSelectorOpen] = useState(true)
  // const [currentTrack, setCurrentTrack] = useState<TrackStatus | undefined>()
  const { playerStatus } = useContext(GlobalContextContext)
  // const [isPlaying, setIsPlaying] = useState(false)
  const [isFavoriteResult, setIsFavorite] = useState<{ id: string; _version: string } | undefined>(
    undefined
  )

  const isStaging =
    window.location.hostname.includes('stg') || window.location.hostname.includes('localhost')

  if (!playlist) return null

  const onPlay = () => {
    return player.isPlaying() ? player.pause() : player.resume()
  }

  const onShuffle = () => {
    player.skipTrack()
    // updateTrackInfo()
  }

  const VolumeSliderWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    svg {
      width: 25px;
      height: 25px;
    }
    .rc-slider {
      margin-left: 1rem;
      margin-right: 1rem;
      height: auto;
    }
  `

  const onAddToFavorite = async () => {
    if (isFavoriteResult) return
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result: any = await API.graphql({
      query: createFavorites,
      variables: { input: { playlistId: playlist.id, destinationId } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
    })
    setIsFavorite(result?.data.createFavorites)
  }
  const onRemoveFavorite = async () => {
    if (!isFavoriteResult) return
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = await API.graphql({
      query: deleteFavorites,
      // eslint-disable-next-line no-underscore-dangle
      variables: { input: { id: isFavoriteResult.id, _version: isFavoriteResult._version } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
    })
    setIsFavorite(undefined)
  }

  const getFavorite = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = (await API.graphql({
      query: listFavorites,
      variables: { filter: { playlistId: { eq: playlist.id } } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
    })) as { data: ListFavoritesQuery }
    const r =
      result &&
      result.data &&
      !!result.data.listFavorites &&
      result.data.listFavorites.items.length > 0 &&
      // eslint-disable-next-line no-underscore-dangle
      result.data.listFavorites.items.filter((item) => !item?._deleted)
    if (!r) {
      setIsFavorite(undefined)
      return
    }
    setIsFavorite(r[0] as any)
  }

  useEffect(() => {
    const setPlaylist = async () => {
      await player.setPlaylist(Number(playlist.id), destinationId, false)
    }
    setPlaylist()
  }, [playlist.id])

  useEffect(() => {
    getFavorite()
  }, [playlist.id])

  const playerIsLoading = () => player.isLoading()

  useEffect(() => {
    // close the playlist selector when the playlist changes
    ;((window as any).eventHandler as EventEmitter).on('CLOSE_ALL', () => {
      setIsPlaylistSelectorOpen(false)
    })
  }, [])

  useEffect(() => {
    // close the playlist selector when the playlist changes
    setIsPlaylistSelectorOpen(false)
  }, [playlist.id])
  useEffect(() => {
    setTimeout(() => {
      setIsPlaylistSelectorOpen(true)
    }, 1000)
  }, [player.destinationId])

  //  TODO: we need callbacks here for the player on the context !!! so that the
  //  player variable updates on changes
  return (
    <Wrapper>
      <PlaylistSelector modalOpen={isPlaylistSelectorOpen}>
        <span>{destinationTitle}</span>
        <div>
          {playlists.map((p) => (
            <PlaylistCard destinationId={destinationId} p={p} />
          ))}
        </div>
      </PlaylistSelector>
      <TrackAndArtist>
        <span>{playerStatus?.playlist?.data?.attributes?.title}</span>
        {isStaging && <span>{playerStatus?.track?.title}</span>}
        <span>{playerStatus?.track?.artist}</span>
      </TrackAndArtist>
      <IconBar>
        <Icon onClick={isFavoriteResult ? onRemoveFavorite : onAddToFavorite}>
          <Bookmark active={!!isFavoriteResult} />
        </Icon>
        <button type="button" onClick={() => setIsPlaylistSelectorOpen(!isPlaylistSelectorOpen)}>
          <IntensitySwitch active={isPlaylistSelectorOpen} />
        </button>
        <PlayIcon
          onClick={onPlay}
          isPlaying={!!playerStatus?.isPlaying}
          isLoading={playerIsLoading()}
        />
        <Icon onClick={onShuffle}>
          <Shuffle />
        </Icon>
      </IconBar>
      {!isIOS() && (
        <VolumeSliderWrapper key="slider">
          <VolumeDown />
          <Slider
            onChange={(nextValues) => player.setVolume(nextValues as number)}
            key="slider-react"
            min={0}
            max={1}
            defaultValue={player.getVolume()}
            step={0.01}
          />
          <VolumeUp />
        </VolumeSliderWrapper>
      )}
    </Wrapper>
  )
}

export default PlayerControl

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PlaylistEntity } from '../../generated/graphql'
import Image from '../image/image'
import { PlayerContext } from '../../utils/player'
import AnimatedLogo from '../../assets/animated_logo.gif'

const CoverImage = styled(Image)``

const PlaylistCardWrapper = styled(Link)`
  display: flex;
  background-color: #f3f3f3;
  width: 100%;
  border-radius: 0.9rem;
  margin-bottom: 0.9rem;
  padding: 0.9rem;
  align-items: center;
  text-decoration: none;
  justify-content: flex-start;
  color: black;
  ${CoverImage} {
    width: 8rem;
    height: 8rem;
    border-radius: 0.8rem;
  }
  /* & > div {
    width: 8rem;
    height: 8rem;
    margin: 0;
    padding: 0;
    border-radius: 0.9rem;
    overflow: hidden;
  } */
`

const Title = styled.span`
  font-family: ${(props) => props.theme.fonts.primary};
  margin-left: 1.5rem;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  text-align: left;
`

const AnimatedLogowrapper = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 1.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  mix-blend-mode: multiply;
`

const ImageWrapper = styled.div`
  position: relative;
`

const PlaylistCard = ({
  p,
  destinationId,
  onClick,
}: {
  p: PlaylistEntity
  destinationId?: number | undefined
  onClick?: (pid: string) => Promise<void> | undefined
}) => {
  const player = useContext(PlayerContext)
  const isCurrent = Number(player.playlistId) === Number(p.id)
  return (
    <PlaylistCardWrapper
      onClick={() => onClick && onClick(p.id as string)}
      to={destinationId ? `/destination/${destinationId}/${p.id}` : '#'}
      key={p.id}
    >
      <ImageWrapper>
        <CoverImage
          // aspectRatio={{ width: 1, height: 1 }}
          src={p?.attributes?.cover?.data?.attributes?.url as string}
          alt={p?.attributes?.cover?.data?.attributes?.alternativeText || ''}
        />
        {isCurrent && <AnimatedLogowrapper src={AnimatedLogo} />}
      </ImageWrapper>
      <Title>{p.attributes?.title}</Title>
    </PlaylistCardWrapper>
  )
}

PlaylistCard.defaultProps = {
  onClick: undefined,
  destinationId: undefined,
}

export default PlaylistCard

import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  background: white;
  border-radius: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  * {
    text-align: center;
    width: 100%;
    font-family: ${(props) => props.theme.fonts.primary};
    font-weight: 300;
    letter-spacing: 0.05em;
  }
  h2 {
    font-size: 3.6rem;
    line-height: 4.2rme;
    text-align: center;
    margin-bottom: 3.6rem;
  }
  p {
    font-size: 6rem;
    line-height: 6.4rem;
    margin: 0;
  }
  span {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
    display: block;
    margin-bottom: 3rem;
    display: block;
    &.crossed {
      margin-bottom: 1.2rem;
      text-decoration: line-through;
    }
    &.spacingTop {
      margin-top: 1.2rem;
      margin-bottom: 0;
    }
  }
  button {
    background: black;
    color: white;
    border: none;
    box-shadow: none;
    font-size: 1.6rem;
    padding: 1rem;
    border-radius: 0.9rem;
    cursor: pointer;
  }
`

const PricingCard: React.FC<{
  headline: string
  price: string
  subline: string
  cancelationLabel: string
  ctaClick: () => void
}> = ({ headline, price, subline, ctaClick, cancelationLabel = '' }) => (
  <Card>
    <h2>{headline}</h2>
    <div>
      <p>{price}</p>
      <span style={{ marginBottom: '1.2rem' }} className={subline.length > 2 ? 'crossed' : ''}>
        {subline}
      </span>
      <span>(inkl. Mwst)</span>
    </div>
    <button type="button" onClick={ctaClick}>
      Upgrade
    </button>
    {cancelationLabel && <span className="spacingTop">({cancelationLabel})</span>}
  </Card>
)

export default PricingCard

/* eslint-disable react/no-unknown-property */
import React from 'react'

const InfoIcon = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16.5" cy="16.4995" r="13.7241" stroke="black" />
    <path
      d="M17.2587 12.5986V22.758H16.255V12.5986H17.2587ZM15.9683 9.3418C15.9683 10.4069 17.5864 10.4069 17.5864 9.3418C17.5864 8.27669 15.9683 8.27669 15.9683 9.3418Z"
      fill="black"
    />
  </svg>
)

export default InfoIcon

import { gql } from '@apollo/client'

const DESTINATIONS_QUERY = gql`
  query {
    destinations {
      data {
        id
        attributes {
          title
          order
          coverImage {
            data {
              id
              attributes {
                url
                alternativeText
                name
              }
            }
          }
        }
      }
    }
  }
`

export default DESTINATIONS_QUERY

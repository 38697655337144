import React, { useEffect } from 'react'
import { Auth, I18n } from 'aws-amplify'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import {
  Authenticator,
  CheckboxField,
  SelectField,
  TextField,
  useAuthenticator,
  translations,
} from '@aws-amplify/ui-react'
// eslint-disable-next-line
import '@aws-amplify/ui-react/styles.css'
import mixpanel from 'mixpanel-browser'
import EventEmitter from 'events'
import styled from 'styled-components'
import Home from './pages/home/Home'
// import Destination from './pages/destination/Destination'
import Player, { PlayerContext, TrackStatus } from './utils/player'
import './styles/index.scss'
import AuthHeader from './components/auth/header'
import styles from './styles/auth.module.scss'
import GlobalContextContext from './utils/globalContext'
import Settings from './pages/settings/Settings'
import Tutorial from './pages/tutorial/tutorial'

mixpanel.init('2bff1730fdc6107289bc826570674e81', { debug: true })

I18n.putVocabularies(translations)
I18n.setLanguage('de')

I18n.putVocabularies({
  de: {
    'Create Account': 'Registrieren',
  },
})

const SignupFooter = styled.p`
  font-family: ${(props) => props.theme.fonts.primary};
  margin-left: 1.5rem;
  font-weight: 400;
  font-size: 13px;
  width: 100%;
  text-align: left;
  text-align: center;
  a {
    text-decoration: underline;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`

const components = {
  Header: AuthHeader,
  SignUp: {
    Footer: () => (
      <SignupFooter>
        Jetzt 7 Tage kostenlos testen.
        <br />
        <a target="_blank" rel="noreferrer" href="https://www.antless.studio/agb">
          AGB
        </a>{' '}
        und{' '}
        <a target="_blank" rel="noreferrer" href="https://www.antless.studio/datenschutz">
          Datenschutzerklärung
        </a>
      </SignupFooter>
    ),
    FormFields() {
      const { validationErrors } = useAuthenticator()

      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <TextField label="" name="custom:firstname" placeholder="Vorname" />
          {/* <SelectField name="custom:listeninglocation" label="In welchem Land hörst du?">
            <option value="Österreich">Österreich</option>
            <option value="Deutschland">Deutschland</option>
            <option value="Anderes Land">Anderes Land</option>
          </SelectField> */}
          <Authenticator.SignUp.FormFields />
          {/* Append & require Terms & Conditions field to sign up  */}
          <CheckboxField
            errorMessage={validationErrors.acknowledgement as string}
            hasError={!!validationErrors.acknowledgement}
            name="acknowledgement"
            value="yes"
            label="Hiermit akzeptiere ich die AGB und Datenschutzerklärung"
          />
          <CheckboxField
            errorMessage={validationErrors.countryAcknowledgement as string}
            hasError={!!validationErrors.countryAcknowledgement}
            name="countryAcknowledgement"
            value="yes"
            label="Hiermit bestätige ich, dass ich ANTLESS in Deutschland oder Österreich nutze."
          />
        </>
      )
    },
  },
  // ConfirmSignUp: {
  //   Footer: ConfirmSignup,
  // },
}

const App = () => {
  const player = React.useMemo<Player>(() => new Player(), [])
  const location = useLocation()
  const { user: currentUser } = useAuthenticator()
  const [playerStatus, setPlayerStatus] = React.useState<TrackStatus>()
  useEffect(() => {
    player.on('playlist-changed', () => setPlayerStatus(player.getTrackStatus()))
    player.on('play', () => setPlayerStatus(player.getTrackStatus()))
    player.on('pause', () => setPlayerStatus(player.getTrackStatus()))
    // global event emitter
    ;(window as any).eventHandler = new EventEmitter()
  }, [player])

  const services = {
    async validateCustomSignUp(formData: any) {
      const err: any = {}
      if (!formData.acknowledgement) {
        err.acknowledgement = 'Bitte akzeptiere die AGB und Datenschutzerklärung'
      }
      if (!formData.countryAcknowledgement) {
        err.countryAcknowledgement =
          'Bitte bestätige, dass du Antless nur in Deutschland oder Österreich nutzt'
      }
      return err
    },
    async handleSignIn(formData: any) {
      const { username, password } = formData
      // custom username
      if (location.search && new URLSearchParams(location.search).has('qr')) {
        const QR = new URLSearchParams(location.search).get('qr') as string
        const authData = await Auth.signIn(
          {
            username: QR,
          } as any,
          '',
          {
            authFlow: 'CUSTOM_AUTH',
          }
        )
        // Complete the custom auth challenge
        const challengeResponse = QR
        const loggedInUser = await Auth.sendCustomChallengeAnswer(authData, challengeResponse)

        // Store the ID token in local storage
        await Auth.essentialCredentials(loggedInUser)
        return authData
      }
      return Auth.signIn(username, password)
    },
  }

  const waitForForm = async (index = 0) => {
    return new Promise((res) => {
      const form = document.querySelector('form')
      if (form || !!currentUser || index > 100) {
        res(true)
      } else {
        setTimeout(async () => {
          await waitForForm(index + 1)
          res(true)
        }, 100)
      }
    })
  }

  const QRLogin = async () => {
    if (!location.search || !new URLSearchParams(location.search).has('qr')) return
    if (currentUser) return
    const QR = new URLSearchParams(location.search).get('qr')
    await waitForForm()
    const form = document.querySelector('form')
    if (!form) return
    const email = form.querySelector('input[type="email"]') as HTMLInputElement
    const password = form.querySelector('input[type="password"]') as HTMLInputElement
    const submit = form.querySelector('button[type="submit"]') as HTMLButtonElement
    if (!email || !password) return
    form.style.opacity = '0'
    email.value = 'qr@antless.studio'
    password.value = '1234567890'
    submit.click()
  }

  useEffect(() => {
    QRLogin()
  }, [])

  return (
    <div className="App">
      <Authenticator services={services} className={styles.root} components={components}>
        {({ signOut, user }) => (
          <PlayerContext.Provider value={player}>
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <GlobalContextContext.Provider value={{ user, signOut, playerStatus }}>
              <Routes>
                <Route path="tutorial" element={<Tutorial />} />
                <Route path="settings" element={<Settings />} />
                <Route index element={<Home user={user} />} />
                <Route path="destination/:id" element={<Home user={user} />} />
                <Route path="destination/:id/:playlist" element={<Home user={user} />} />
              </Routes>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <audio
                autoPlay
                id="audioTag"
                style={{ visibility: 'hidden', position: 'fixed', left: 0, top: 0 }}
              />
            </GlobalContextContext.Provider>
          </PlayerContext.Provider>
        )}
      </Authenticator>
    </div>
  )
}

export default App

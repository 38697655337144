import React from 'react'
import styled from 'styled-components'
import DestinationCard from './destinationCard'
import RecommendedPlaylist from '../recommendedPlaylist/recommendedPlaylist'

const SliderWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.9rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (max-width: ${(props) => props.theme.fonts.primary}) and (orientation: landscape) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${(props) => props.theme.breakpoints.md} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 0.9rem;
  }
  // overflow-x: scroll;
  // scroll-snap-type: x mandatory;
  // gap: 12px;
  // padding: 0 2rem;
  // @media ${(props) => props.theme.breakpoints.md} {
  //   padding: 0 4rem;
  // }
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // & > div {
  //   width: 90%;
  //   max-width: 330px;
  //   flex-shrink: 0;
  //   scroll-snap-align: center;
  // }
`

interface Props {
  data: [any]
}

const DestinationSlider = ({ data }: Props) => {
  return (
    <SliderWrapper>
      {data &&
        data
          .slice()
          .sort((a: any, b: any) => a.attributes.order - b.attributes.order)
          .map((destination: any, index: number) => {
            return (
              <div key={destination.id}>
                <DestinationCard index={index} data={destination} />
              </div>
            )
          })}
      <RecommendedPlaylist />
    </SliderWrapper>
  )
}

export default DestinationSlider

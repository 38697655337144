/* eslint-disable react/no-unknown-property */
import React from 'react'

const VolumeUp = () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.39394 5.13951V5.6395C4.51411 5.63951 4.63026 5.59623 4.72113 5.51759L4.39394 5.13951ZM8.75758 1.36328H9.25758C9.25758 1.16759 9.14341 0.989883 8.96543 0.908532C8.78745 0.827181 8.57836 0.85714 8.43039 0.985197L8.75758 1.36328ZM1 5.1395L1 4.6395C0.867392 4.6395 0.740215 4.69218 0.646447 4.78595C0.552678 4.87972 0.5 5.0069 0.5 5.1395H1ZM1 9.85979H0.5C0.5 10.1359 0.723858 10.3598 1 10.3598V9.85979ZM4.39394 9.85979L4.72113 9.4817C4.63026 9.40306 4.51411 9.35979 4.39394 9.35979V9.85979ZM8.75758 13.636L8.43039 14.0141C8.57836 14.1422 8.78745 14.1721 8.96543 14.0908C9.14341 14.0094 9.25758 13.8317 9.25758 13.636H8.75758ZM4.72113 5.51759L9.08476 1.74137L8.43039 0.985197L4.06675 4.76142L4.72113 5.51759ZM1 5.6395H4.39394V4.63951L1 4.6395L1 5.6395ZM1.5 9.85979V5.1395H0.5V9.85979H1.5ZM4.39394 9.35979H1V10.3598H4.39394V9.35979ZM9.08476 13.2579L4.72113 9.4817L4.06675 10.2379L8.43039 14.0141L9.08476 13.2579ZM8.25758 1.36328V13.636H9.25758V1.36328H8.25758Z"
      fill="black"
    />
    <path
      d="M11.6667 5.45508C11.6667 5.45508 12.1515 5.96644 12.1515 7.50053C12.1515 9.03462 11.6667 9.54599 11.6667 9.54599"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8485 4.08984C13.8485 4.08984 14.5758 4.94212 14.5758 7.49893C14.5758 10.0558 13.8485 10.908 13.8485 10.908"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0303 3.18164C16.0303 3.18164 17 4.26119 17 7.49982C17 10.7385 16.0303 11.818 16.0303 11.818"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default VolumeUp

// eslint ignore react/no-unknown-property
import React from 'react'

const Play = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      style={{ transform: 'translateX(30px)' }}
      fill="transparent"
      stroke="#000"
      strokeWidth={17}
      d="M405.2 232.9 126.8 67.2A20 20 0 0 0 96.1 84H96v344h.1c0 11 8.9 20 19.8 20 4.1 0 7.5-1.4 11.2-3.4l278.1-165.5a30 30 0 0 0 0-46.2z"
    />
  </svg>
)

export default Play

import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import styled from 'styled-components'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { createStripeCheckoutSession } from '../../graphql/mutations'
import { CreateStripeCheckoutSessionMutation } from '../../API'
import PricingCard from './pricingCard'
import CompanyCode from './companyCode'
import Spinner from '../generic/Spinner'
import Settings from '../../assets/Icons/Settings'

const ExpiredOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 1.8rem;
  & > div {
    width: calc(100vw - 3.6rem);
    height: 100%;
    position: relative;
    background: ${(props) => props.theme.colors.lightMint};
    padding: 2rem;
    border-radius: 0.5rem;
    padding: 2rem;
    padding-top: 4rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    @media ${(props) => props.theme.breakpoints.md} {
      width: 80vw;
      padding: 9rem 12rem;
    }
    h1 {
      display: block;
      margin-bottom: 2rem;
      font-family: ${(props) => props.theme.fonts.primary};
      font-style: normal;
      font-weight: 300;
      font-size: 3.6rem;
      line-height: 4.2rem;
      letter-spacing: 0.04em;
    }
  }
`

const InfoText = styled.p`
  letter-spacing: 0.18em;
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: 300;
`

const CardWrapper = styled.div`
  width: 100%;
  gap: 3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  @media ${(props) => props.theme.breakpoints.md} {
    margin-bottom: 0;
    flex-direction: row;
  }
`

const SettingsLink = styled.div`
  position: absolute;
  z-index: 9999;
  width: 4rem;
  top: 1.8rem;
  right: 1.8rem;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PayGate: React.FC<{ user: CognitoUser | undefined }> = ({ user }) => {
  const navigate = useNavigate()
  const [expired, setExpired] = useState(false)
  const [isUser, setIsUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const getUserStatus = (redirectAfter: boolean) => {
    if (!user) return
    user.getUserAttributes((err, attributes) => {
      // const expires = user?.attributes['custom:subscriptionExpires']
      const expiresAttr = attributes?.find(
        (attr) => attr.getName() === 'custom:subscriptionExpires'
      )
      const comanyId = attributes?.find((attr) => attr.getName() === 'custom:companyId')
      if (comanyId) {
        // this user belongs to a company
        // subscription is valid through the company
        setIsLoading(false)
        return setExpired(false)
      }
      const stripeId = attributes?.find((attr) => attr.getName() === 'custom:stripeId')
      if (expiresAttr) {
        const expires = new Date(Number(expiresAttr.getValue()))
        const isExpired = new Date().getTime() > expires.getTime()
        setIsLoading(false)
        setExpired(isExpired)
        if (!isExpired && redirectAfter) {
          navigate('/tutorial')
        }
        setIsUser(!!stripeId)
      } else {
        setExpired(true)
      }
      return null
    })
  }

  useEffect(() => {
    getUserStatus(false)
  }, [user])

  useEffect(() => {
    if (searchParams.get('payment') && searchParams.get('payment') === 'success') {
      setIsLoading(true)
      // this user just came from stripe
      // show a loading screen and retrieve the user a few times to wait for the successful user update
      for (let i = 1; i <= 5; i += 1) {
        setTimeout(() => {
          getUserStatus(true)
        }, 2000 * i)
      }
    }
  }, [user])

  const openCheckoutSession = async (duration: 'MONTHLY' | 'ANNUALLY') => {
    if (isUser) return navigate('/settings')
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = (await API.graphql({
      query: createStripeCheckoutSession,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
      variables: {
        duration,
      },
    })) as { data: CreateStripeCheckoutSessionMutation }
    window.location.href = result.data.createStripeCheckoutSession as string
    return null
  }

  if (process.env.NODE_ENV === 'development') return null
  ;(window as any).expired = expired
  if (!expired) return null

  return (
    <ExpiredOverlay>
      {isLoading && (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      )}
      {!isLoading && (
        <div>
          <div>
            {!isUser && (
              <h1>
                Starte deinen 7-Tage gratis Testzeitraum
                <br />
                <br />
                Wähle deinen Plan:
              </h1>
            )}
            {isUser && <h1>Dein Abo ist abgelaufen, bitte verlängere es in den Einstellungen.</h1>}
            <CardWrapper>
              <PricingCard
                headline="Monatliche Abrechnung"
                price="€18/Monat"
                subline=" "
                cancelationLabel="monatlich kündbar"
                ctaClick={() => openCheckoutSession('MONTHLY')}
              />
              <PricingCard
                headline="Jährliche Abrechnung"
                price="€180/Jahr"
                subline="statt 216€"
                cancelationLabel="jährlich kündbar"
                ctaClick={() => openCheckoutSession('ANNUALLY')}
              />
            </CardWrapper>
            <InfoText>
              Du hast einen Gutschein-Code (beginnt mit VOUxxx)?
              <br /> Wähle deinen Plan und gib den Code im nächsten Schritt ein.
            </InfoText>
          </div>
          <CompanyCode />
          <SettingsLink>
            <Link to="/settings">
              <Settings />
            </Link>
          </SettingsLink>
        </div>
      )}
    </ExpiredOverlay>
  )
}

export default PayGate

import React from 'react'
import styled, { keyframes } from 'styled-components'

const circleSize = '4rem'

const keyframe1 = keyframes`
 0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`

const keyframe2 = keyframes`
  0%, 25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  
  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  height: ${circleSize};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: ${circleSize};
`

const Svg = styled.svg`
  animation: 2s linear infinite both ${keyframe1};
`

const Circle = styled.circle`
  animation: 1.4s ease-in-out infinite both ${keyframe2};
  fill: transparent;
  stroke: white;
  stroke-dasharray: 285;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
`

const Spinner: React.FC = () => (
  <Container>
    <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="50" cy="50" r="45" />
    </Svg>
  </Container>
)

export default Spinner

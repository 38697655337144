import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { DestinationEntityResponseCollection } from '../../generated/graphql'
import RECOMMENDED_PLAYLIST from './recommendedPlaylist.query'
import PlaylistCard from '../playlistCard/playlistCard'

const Tagline = styled.span`
  font-family: ${(props) => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.5rem;
  letter-spacing: 0.05em;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`

const RecommendedPlaylist = () => {
  const now = dayjs().startOf('minute').format('HH:mm:00.000')
  const { data } = useQuery<{ destinations: DestinationEntityResponseCollection }>(
    RECOMMENDED_PLAYLIST,
    {
      variables: { currentTime: now },
      pollInterval: 1000 * 60 * 5,
    }
  )
  const destination = data?.destinations.data.find((d) => {
    return !!d.attributes?.playlists?.data.length
  })
  const playlist = destination?.attributes?.playlists?.data[0]
  if (!playlist) return null
  return (
    <Wrapper>
      <div />
      <PlaylistCard p={playlist} destinationId={Number(destination.id as string)} />
      <Tagline>Recommended for you</Tagline>
    </Wrapper>
  )
}

export default RecommendedPlaylist
